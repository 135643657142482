document.title = '企业数字管理系统'
import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
import '../theme/index.css'
import 'view-design/dist/styles/iview.css';
import router from './router'
import './App.vue'
import store from './store'
import Layout from './layout/index.vue'
import './router/permission'
import axios from 'axios'
import './icons'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'

Vue.prototype.$dayjs = dayjs

import { tim } from '@/util/tim.js'
Vue.prototype.$tim = tim

import * as util from '@/util/util.js'
Vue.prototype.$util = util

import { hasPermissions } from '@/util/util.js'
Vue.prototype.$hasPermissions = hasPermissions

import VueJsonp from 'vue-jsonp'
Vue.use(VueJsonp)

import plugins from './components/common/index.js'
Vue.use(plugins)

import _ from 'lodash'
Vue.prototype._ = _ 

import Meta from "vue-meta";
Vue.use(Meta);

import Print from 'vue-print-nb'
Vue.use(Print);

Vue.prototype.$bus=new Vue({})

import { DatePicker } from 'view-design';
import {resetRouter} from './router/index.js'
Vue.component('DatePicker', DatePicker);

Vue.use(ElementUI);

Vue.config.productionTip = false
// 拦截Axios发起的所有请求，给请求添加token
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem("token");
        if (token) {
            config.headers["Authorization"] = 'Bearer' + token;
        }
		if(sessionStorage.getItem("guardName")){
			config.headers["guardName"] = sessionStorage.getItem("guardName");
		}
		if(sessionStorage.getItem("shopId")){
			config.headers["shopId"] = sessionStorage.getItem("shopId");
		}
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
axios.interceptors.response.use(
  response => {
    //拦截响应，做统一处理 
	if(response.status==200){
		if(response.data.code==401){
			if(store.state.sdk_ready){
				tim.logout()
			}
			if(sessionStorage.getItem('userinfo')){
				let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				let guardName = userinfo.guard_name||sessionStorage.getItem('guardName');
				//判断登出到某个后台登录页面
				router.replace({path: "/login",query:{shopId : sessionStorage.getItem('shopId'),guardName:guardName}})
			}else{
				router.replace({path: "/login",query:{shopId : sessionStorage.getItem('shopId')}})
			}
			resetRouter()
			store.commit('changeRouteadds', 0)
			sessionStorage.removeItem('IMtoken')
			sessionStorage.removeItem('routestext')
			sessionStorage.removeItem('routes')
			sessionStorage.removeItem('userinfo')
			sessionStorage.removeItem('token')
		}else{
			return response.data
		}
	}else{
		util.eltips('服务器错误','error')
		return {code:500,msg:'服务器错误'};
	}
  },error=>{
	  console.log(error)
	  // util.eltips('服务器错误','error')
  }
 )
Vue.prototype.axios=axios

new Vue({
  router,
  store,
  axios,
  render: h => h(Layout)
}).$mount('#app')
