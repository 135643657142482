import TIM from '@tencentcloud/chat/index.es.js';
import TIMUploadPlugin from 'tim-upload-plugin'
import store from '../store'

export let tim = TIM.create({
	SDKAppID: 1400583597
})

tim.setLogLevel(1)
tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin})

//SDK状态判断
tim.on(TIM.EVENT.SDK_READY, setSdkReady);
tim.on(TIM.EVENT.SDK_NOT_READY, setSdkNotReady);
function setSdkReady(event){
	store.commit('changeSdkReady', true)
	store.getters.totalUnreadCount
}
function setSdkNotReady(event){
	store.commit('changeSdkReady', false)
}

//收到消息监听
tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived)
function onMessageReceived(event){
	for (let i = 0; i < event.data.length; i++) {
	  let item = event.data[i]
	}
	store.dispatch('onMessageEvent', {event:event,_this:tim})
	store.getters.totalUnreadCount
}

// 收到会话列表更新通知，可通过遍历 event.data 获取会话列表数据并渲染到页面
tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, convListUpdate)
function convListUpdate (event) {
  store.commit('updateAllConversation', event.data)
}

// 收到对端已读消息的通知，即已读回执。使用前需要将SDK版本升级至v2.7.0或更高版本。仅支持单聊会话。
tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer)
function onMessageReadByPeer(event){
	store.dispatch('onMessageRead', event)
}

//TIM登录
export function timLogin(){
	let userID = (JSON.parse(sessionStorage.getItem('userinfo'))).im_account
	let userSig = sessionStorage.getItem('IMtoken') 
	return new Promise((resolve, reject) => {
		tim.login({userID,userSig: userSig}).then((res) => {
			console.log('----------IM登录成功-----------',res)
			resolve('ok')
		}).catch((err) => {
			console.log('---------im登录失败---------' , err)
			resolve('err')
		})
	});
}