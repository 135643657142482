<template>
	<div id="layout" style="position: relative;">
		<template>
			<template v-if="$route.meta.layout&&$route.meta.layout!=0">
				<div style="display: inline-block;vertical-align:top;" :style="{'width':chatShow?'calc(100% - 360px)':'100%'}">
					<headernav style="position: relative;z-index: 999;"></headernav>
					<div style="display: flex;">
						<asidenav class="leftbox"></asidenav>
						<div class="rightbox">
							<div class="tagbox">
								<template v-for="(item,index) in historyRoute">
									<span class="taglist" :class="{'active':$route.meta.id==item.meta.id}" :key="index" @click="toLink(item)">
										<!-- {{item.meta.title}} -->
										<span>
											<template v-for="(tag,i) in item.matched">
												<span class="tag" :key="i">{{tag.meta.title}}</span>
											</template>
										</span>
										
										
										
										
										<i class="delIcon el-icon-close" v-if="$route.meta.id!=item.meta.id" @click.stop="delTag(index)"></i>
									</span>
								</template>
							</div>
							<div class="mainbox">
								<router-view/>
							</div>
						</div>
					</div>
				</div>
				<div class="chatDrawerBox" :style="{'width':chatShow?'360px':'0','height':chatShow?'100vh':'0'}">
					<transition name="transitionChatDrawer">
						<chatDrawer class="chatDrawer" v-if="chatShow"></chatDrawer>
					</transition>
				</div>
				
				<transition name="transitionChatIcon">
					<div class="layoutChatIconbox" @click="changeChatShow()" v-show="!chatShow">
						<div :class="{'layoutChatAnimation':totalUnreadCount&&!chatShow}">
							<el-badge :value="totalUnreadCount" :max="99" :hidden="!totalUnreadCount">
							  <svg-icon class="layoutChatIcon" iconClass="layoutChat"></svg-icon>
							</el-badge>
						</div>
					</div>
				</transition>
			</template>
			
			<router-view v-else/>
		</template>
	</div>
</template>

<script>
import headernav from './components/headernav.vue'
import asidenav from './components/asidenav.vue'
import watermark from '@/util/watermark.js'
import { mapGetters,mapState } from 'vuex';
import chatDrawer from '@/components/chatDrawer/chatDrawer.vue'
export default {
	name: 'layout',
	components:{headernav,asidenav,chatDrawer},
	data() {
		return {
			blinkInterval: null,
			originalTitle: document.title,
			historyRoute:[],
		};
	},
	computed: {
		...mapGetters({
			totalUnreadCount: 'totalUnreadCount',
		}),
		...mapState({
			chatShow: state => state.chatShow,
		})
	},
	mounted(){
		const route = this.$route;
		if(route.path!='/login'&&route.path!='/adminlogin'){
			const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
			if(userinfo) watermark.set(userinfo.guard_name=='api'?userinfo.shop_name:userinfo.username,userinfo.guard_name=='api'?userinfo.shop_name:userinfo.phone);
		}else{
			this.historyRoute = []
			watermark.close()
		}
	},
	watch:{
		'$route':function(route){
			if(route.path!='/login'&&route.path!='/adminlogin'&&route.path!='/partnerlogin'){
				const userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				if(userinfo) watermark.set(userinfo.guard_name=='api'?userinfo.shop_name:userinfo.username,userinfo.guard_name=='api'?userinfo.shop_name:userinfo.phone);
				this.addRouteHistory(route)
			}else{
				this.historyRoute = []
				this.stopBlinking()
				watermark.close()
			}
		},
		'$store.getters.totalUnreadCount':function(val){
			if(val>0){
				this.startBlinking()
			}else{
				this.stopBlinking()
			}
		}
	},
	methods:{
		delTag(index){
			this.historyRoute.splice(index,1)
		},
		toLink(item){
			if(item.meta.id==this.$route.meta.id) return;
			let router = {path:item.path}
			if(item.meta.props){
				router.query = {props: item.meta.props}
			}
			this.$router.push(router)
		},
		addRouteHistory(route){
			const index = this.historyRoute.findIndex(e=>e.meta.id==route.meta.id);
			// if(index!=-1) this.historyRoute.splice(index,1)
			// this.historyRoute.unshift(route)
			if(index!=-1) return;
			this.historyRoute.push(route)
		},
		changeChatShow(){
			this.$store.commit('changeChatShow',true)
		},
		startBlinking() {
			if(!this.blinkInterval){
				this.blinkInterval = setInterval(() => {
					document.title = document.title!='\u200E' ?'\u200E' : `【新消息】有新的消息待处理`;
				}, 1000);
			}
		},
		stopBlinking() {
			if (this.blinkInterval) {
				clearInterval(this.blinkInterval);
				this.blinkInterval = null;
			}
			document.title = this.originalTitle;
		}
	}
}
</script>

<style lang="less" scoped>
	.leftbox{
		flex: 0 0 200px;
		width: 200px;
		height: calc(100vh - 50px);
		overflow-y: auto;
	}
	
	.rightbox{
		flex: 1;
		height: calc(100vh - 50px);
		position: relative;
		overflow: hidden;
		overflow-y: hidden;
		padding: 10px;
	}
	
	.tagbox{
		margin-bottom: 10px;
		// padding:0 10px;
		height: 34px;
		overflow: hidden;
		// background: #FFFFFF;
		// overflow-x: auto;
		white-space: nowrap;
		.taglist{
			display: inline-block;
			// margin-right: 20px;
			height: 34px;
			line-height: 34px;
			padding: 0 10px;
			cursor: pointer;
			font-size: 13px;
			color: #888;
			border-radius: 2px;
			.tag{
				&::after{
					content: '-';
				}
				&:last-child::after{
					content: '' !important;
					width: 0;
					height: 0;
					overflow: hidden;
				}
			}
		}
		
		.active{
			// background: #e8f3ff;
			// color: #1989fa;
			background: #1989fa;
			color: #ffffff;
			cursor: default;
			// .tag{
			// 	&:nth-last-child(2)::after{
			// 		content: ' / ' !important;
			// 	}
			// }
		}
		
		.delIcon{
			font-size: 14px;
			margin-left: 2px;
		}
	}
	
	.mainbox{
		height: calc(100vh - 118px);
		position: relative;
		overflow-y: auto;
		overflow-x: hidden;
	}
	
	.chatDrawerBox{
		display: inline-block;
		vertical-align:top;
		position: relative;
		overflow-x: hidden;
		z-index: 100;
	}
	
	.transitionChatDrawer-enter-active,.transitionChatDrawer-leave-active{
		transition: width 0.3s;
	}
	 .transitionChatDrawer-leave,.transitionChatDrawer-enter-to {
		width: 360px !important;
	}
	.transitionChatDrawer-enter, .transitionChatDrawer-leave-to {
		width: 0px !important;
	}
	
	
	
	.transitionChatIcon-enter-active,.transitionChatIcon-leave-active{
		transition: right 0.3s;
	}
	 .transitionChatIcon-leave,.transitionChatIcon-enter-to, {
		right: 0 !important;
	}
	.transitionChatIcon-enter, .transitionChatIcon-leave-to {
		right: -62px !important;
	}
	
	.layoutChatIconbox{
		position: fixed;
		bottom: 30%;
		right: 0px;
		z-index: 9999;
		background: #e8e8e8;
		border-radius: 8px 0 0 8px;
		cursor: pointer;
		padding: 12px 20px 6px 18px;
		-webkit-user-select: none; /* Chrome, Safari, Opera */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
		.layoutChatIcon{
			font-size: 24px;
			color: #1989fa;
		}
		&:hover{
			background: #1989fa;
			.layoutChatIcon{
				color: #FFFFFF;
			}
			.layoutChatAnimation{
				animation:none;
			}
		}
	}
	.layoutChatAnimation{
		animation: twinkle .5s infinite alternate;
	}
	@keyframes twinkle {
		0%{
			opacity: 0;
		}
		100%{
			opacity: 1;
		}
	}
</style>
