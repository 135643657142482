<template>
	<div id="copmonentsConversation" v-if="currentConversationID">
		<el-image ref="elImageRef" v-show="false" class="img" :src="previewSrc" :preview-src-list="[previewSrc]"></el-image>
		<div class="chatBox">
			<div class="chatInfo">
				<div class="avatarbox">
					<el-image class="avatar" v-if="judgeAccountType('ORS',toAccount)" :src="systemInfo.logo" fit="cover"></el-image>
					<el-image class="avatar" v-else :src="currentConversation.userProfile.avatar||require('assets/icon-avatar.png')" fit="cover"></el-image>
				</div>
				<div class="infobox">
					<div class="userinfo">
						<div class="nick" v-if="judgeAccountType('ORW',toAccount)">{{currentConversation.userProfile.nick||`求职者${currentConversation.userProfile.userID}`}}</div>
						<div class="nick" v-else>
							<div class="admin" v-if="judgeAccountType('ORS',toAccount)">
								系统管理员
								<svg-icon class="icon" iconClass="auth"></svg-icon>
							</div>
							<span v-else>
								{{currentConversation.userProfile.nick}}
								{{adminInfo?' · '+adminInfo.phone:''}}
							</span>
						</div>
					</div>
				</div>
			</div>
			<div class="chatView" ref="chatView" @scroll="chatScroll">
				<div v-if="currentMessageList.length==0" class="notdata">
					<el-image class="icon" :src="require('assets/icon-nodata.png')" fit="cover"></el-image>
					<div class="tips">暂无聊天记录，聊天记录仅保存7天</div>
				</div>
				
				<div id="`C2C${item.ID}`" v-for="(item ,index) in currentMessageList" :key="index">
					<template v-if="!item.isDeleted">
						<div v-if="item.isRevoked" style="text-align: center;font-size: 13px;color: #888;margin-bottom: 15px;">{{item.from==toAccount?'对方':'你'}}撤回一条消息</div>
						<div v-else style="margin-bottom: 35px;" :class="item.from==toAccount?'left':'right'">
							<div class="msgDate">{{dateFormat(item.time)}}</div>
							<div class="msgbox clearFloat" :id="`C2C${item.ID}`">
								<div class="avatarbox">
									<el-image class="avatar" v-if="judgeAccountType('ORS',item.from)" :src="systemInfo.logo" fit="cover"></el-image>
									<el-image class="avatar" v-else :src="item.avatar||require('assets/icon-avatar.png')" fit="cover"></el-image>
								</div>
								<div class="contentbox">
									<div class="text" v-if="item.type == 'TIMTextElem'" >{{item.payload.text}}</div>
									<div class="imgbox" v-if="item.type == 'TIMImageElem'">
										<el-image class="image" :src="item.payload.imageInfoArray[0].url" :preview-src-list="[item.payload.imageInfoArray[0].url]" fit="cover"></el-image>
									</div>
									<div class="locationBox" v-if="item.type=='TIMLocationElem'" @click="openTMap(item.payload.latitude,item.payload.longitude,item.payload.description)">
										<i class="icon el-icon-map-location"></i>
										<div class="locationtext">
											<template v-if="item.payload.description.indexOf('{')!=-1">
												<div class="locationtext">{{JSON.parse(item.payload.description).name}}</div>
												<div class="locationtext" style="font-size: 12px;margin-top: 3px;">{{JSON.parse(item.payload.description).address}}</div>
											</template>
											<div class="locationtext" v-else>{{item.payload.description}}</div>
										</div>
									</div>
									<template v-if="item.type=='TIMCustomElem'">
									<div v-if="item.payload.description=='pushJob'||item.payload.description=='deliverJob'" class="jobBox" @click="openJob(item.payload.data)">
										<div class="titlebox">
											<div class="name">{{JSON.parse(item.payload.data).title}}</div>
											<div class="price" v-if="JSON.parse(item.payload.data).salary_type==1">{{`${parseFloat(JSON.parse(item.payload.data).salary_start)}/小时`}}</div>
											<div class="price" v-if="JSON.parse(item.payload.data).salary_type==2">{{`${parseFloat(JSON.parse(item.payload.data).salary_start)}-${parseFloat(JSON.parse(item.payload.data).salary_end)}/月`}}</div>
											<div class="price" v-if="JSON.parse(item.payload.data).salary_type==3">{{`面议`}}</div>
										</div>
										<div class="textbox">
											<div class="info">{{JSON.parse(item.payload.data).requirement}}</div>
											<div class="info">{{JSON.parse(item.payload.data).company}}</div>
										</div>
									</div>
										<div v-if="item.payload.description=='interview'">
											<div>邀请用户面试</div>
											<!-- <div>职位名称：</div> -->
										</div>
									</template>
								</div>
								<div class="msgIcon">
									<i class="el-icon-loading" v-if="item.status=='unSend'"></i>
									<i class="el-icon-info" style="color: #FF0000;" v-if="item.status=='fail'"></i>
									<span v-if="item.status=='success'&&item.from!=toAccount" style="font-size: 12px;color: #999;margin: 0 4px;">{{item.isPeerRead?'已读':'未读'}}</span>
								</div>
							</div>
							<div class="msgTips" v-if="item.from==toAccount">
								<span v-if="item.type=='TIMLocationElem'">对方向你发送了一个位置信息，点击上方卡片查看</span>
								<span v-if="item.type=='TIMCustomElem'&&item.payload.description=='pushJob'">对方向你咨询一个职位信息，点击上方卡片查看</span>
								<span v-if="item.type=='TIMCustomElem'&&item.payload.description=='deliverJob'" style="color: #FF5A5F;">对方投递了该职位，请尽快处理</span>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div class="chatInput">
				<div class="menubox">
					<div class="iconbox" @click="$refs.uploadImg.click()">
						<i class="icon el-icon-picture-outline"></i>
						<!-- <span>发送图片</span> -->
						<input class="uploadInput" id="uploadImg" ref="uploadImg" accept="image/*" type="file" :multiple="false" @change="chooseImage"/>
					</div>
					<!-- <div class="iconbox" @click="openChooseLocation">
						<i class="icon el-icon-place"></i>
					</div> -->
				</div>
				<div 
					class="contenteditable" 
					ref="contenteditable" 
					contenteditable
					@paste="handlePaste"
					@keydown.enter.ctrl="pressCtrlEnter"
					@keydown.enter.prevent="pressEnter"
					>
				</div>
				<div class="btnbox">
					<!-- <div class="btnsty" @click="sendMsg(1)" title="按Enter键快捷发送">发送信息</div> -->
					<span class="tips">Enter发送消息，Ctrl+Enter换行</span>
					<el-button type="primary" size="small" @click="sendMsg()">发送信息</el-button>
				</div>
			</div>
		</div>
		

	</div>
</template>

<script>
	import { mapState,mapGetters } from 'vuex';
	import {getContenteditableVal} from './processingMsg.js' //获取消息内容
	import { handlePaste,handleImage } from './pasteMsg.js' //处理粘贴内容
	export default {
		name: "copmonentsConversation",
		data() {
			return {
				systemInfo:JSON.parse(sessionStorage.getItem('systemInfo')),
				myInfo:JSON.parse(sessionStorage.getItem('userinfo')),
				is_up:false,
				jsWebScreenShot:null,
				previewSrc:null,
				adminInfo:null,
				x:'',
				y:'',
				show:false,
				is_remove:false,
				messageObj:'',
				messageIndex:'',
			};
		},
		watch:{
			currentMessageList:{
				handler(val) {
					if(this.is_up){
						this.is_up = false
						if(val.length==0) return
						this.$nextTick(() => {
							let el =  this.$refs.chatView
							let newHeight = el.scrollHeight || 0
							if(!newHeight) return;
							let scrollTop = newHeight - this.chatHeight
							el.scrollTop=scrollTop
						});
						return
					}
					if(val.length>0){
						this.$nextTick(()=>{
							this.scrollToBottom()
						})
					}
				},
				immediate: true
			},
		},
		computed: {
			...mapGetters({
				toAccount: 'toAccount',
			}),
			...mapState({
				allConversation: state => state.conversation.allConversation,
				currentConversationID: state => state.conversation.currentConversationID,
				currentConversation: state => state.conversation.currentConversation,
				currentMessageList: state => state.conversation.currentMessageList,
			})
		},
		created() {
			this.$on('imgPreview', this.imgPreview);
		},
		mounted() {
			if(this.toAccount&&this.judgeAccountType('ORA',this.toAccount)){
				this.getAdminInfo(this.toAccount)
			}
		},
		destroyed() {
			this.$store.commit('resetCurrentConversation')
			this.$off('imgPreview');
		},
		methods:{
			sendMsg(){
				let textContent = getContenteditableVal(this.$refs.contenteditable.innerHTML).textContent
				let imageFiles = getContenteditableVal(this.$refs.contenteditable.innerHTML).imageFiles
				//发送文字消息
				if((textContent.replace(/\s/g,'').length>0)){
					this.sendTimMessage(textContent,'text')
				}
				//发送图片消息
				if(imageFiles.length>0){
					imageFiles.forEach(file=>{
						this.sendTimMessage(file,'image')
					})
				}
			},
			sendTimMessage(content,type){
				let message;
				if(type=='text'){
					message = this.$tim.createTextMessage({
						to: this.toAccount,
						conversationType: 'C2C',
						payload: {
							text: content,
						}
					});
				}
				
				//发送图片
				if (type == 'image') {
					message = this.$tim.createImageMessage({
						to: this.toAccount,
						conversationType: 'C2C',
						payload: { file: content }
					});
				}
				
				this.$store.commit('sendMessage', message);
				this.$refs.contenteditable.innerHTML = '';
				this.$tim.sendMessage(message).then().catch(err => {
					if(err.code==80001){
						eltips('发送失败，内容包含有敏感词汇')
					}else if(err.code==60020){
						eltips('系统错误，请联系管理员')
					}else{
						console.log(err)
						eltips('发送失败')
					}
				})
			},
			pressEnter(event){
				event.preventDefault();
				if (!event.ctrlKey) {
					// console.log(getContenteditableVal(this.$refs.contenteditable.innerHTML))
					this.sendMsg()
				}
			},
			pressCtrlEnter(event){
				event.preventDefault();
				if (event.ctrlKey) {
					const selection = window.getSelection();
					const range = selection.getRangeAt(0);
					const br = document.createElement('br');
					range.deleteContents();
					range.insertNode(br);
					range.setStartAfter(br);
					range.setEndAfter(br);
					selection.removeAllRanges();
					selection.addRange(range);
				}
				
			},
			getAdminInfo(im_account){
				this.axios.get('/api/oa/Employee/getStaffDetails',{params:{im_account:im_account}}).then(res=>{
					if(res.code==200){
						this.adminInfo = res.data
					}else{
						eltips(res.msg)
					}
				})
			},
			imgPreview(src){
				this.previewSrc = src
				this.$nextTick(()=>{
					this.$refs.elImageRef.showViewer = true
				})
			},
			chooseImage(e){
				handleImage(e.target.files[0],this.$refs.contenteditable,this)
				this.$refs['uploadImg'].value = '' 
			},
			handlePaste(event){
				handlePaste(event,this.$refs.contenteditable,this)
			},
			scrollToBottom() {
				this.$nextTick(()=>{
				   this.$refs.chatView.scrollTop = this.$refs.chatView.scrollHeight
				})
			},
			judgeAccountType(type='ORW',toAccount = this.toAccount){
				if(toAccount.includes(type)){
					return true
				}else{
					return false
				}
			},
			chatScroll(){
				this.show = false
				let el = this.$refs.chatView
				let scrollTop = el.scrollTop
				if(scrollTop == 0&&!this.historyLoading){
					this.getHistory()
				}
			},
			getHistory(){
				this.historyLoading = true
				this.is_up = true
				this.chatHeight = this.$refs.chatView.scrollHeight
				this.$store.dispatch('getMessageList',this) .then(e => {
					this.historyLoading = false
				}).catch(e => {
					this.historyLoading = false
				});
			},
			dateFormat(date){
				return this.$util.setTimeStyle(date*1000,1)
			}
		}
	};
</script>

<style lang="less" scoped>
	#copmonentsConversation{
		height: 100%;
	}
	
	.chatBox{
		width: 100%;
		height: 100%;
		background: #f4f6f8;
		.chatInfo{
			display: flex;
			align-items: center;
			padding: 12px 0;
			border-bottom: 1px solid #ebebeb;
			position: relative;
			background: #FFFFFF;
			.avatarbox{
				flex:  0 0 28px;
				width: 28px;
				height: 28px;					
				margin-right: 12px;
				position: relative;
				.avatar{
					width: 28px;
					height: 28px;
					border-radius: 4px;
				}
			}
			.infobox{
				flex: 1;
				width: 0;
				.userinfo{
					display: flex;
					align-items: center;
					justify-content: space-between;
					.nick{
						flex: 1;
						font-size: 15px;
						line-height: 1;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.admin{
						display: flex;
						align-items: center;
						line-height: 1;
						color: #1989fa;
						.icon{
							margin-left: 4px;
						}
					}
				}
			}
		}
		.chatView{
			padding: 12px;
			height: calc( 100% - 260px - 50px);
			width: 100%;
			overflow-x: hidden;
			overflow-y: auto;
			padding-bottom: 30px;
			.msgbox{
				position: relative;
				.avatarbox{
					width: 38px;
					height: 38px;
					.avatar{
						width: 38px !important;
						height: 38px !important;
						border-radius: 4px;
					}
				}
				.contentbox{
					max-width: calc(100% - 87px);
					position: relative;
					background-color: #1989fa;
					color: #FFFFFF;
					border-radius: 6px;
					padding:9px;
					margin-top: 2px;
					.text{
						font-size: 14px;
						white-space: pre;
					}
					.imgbox{
						max-width: 150px;
						max-height: 300px;
						.image{
							max-width: 150px;
							max-height: 300px;
						}
					}
					.locationBox{
						width: 250px;
						cursor: pointer;
						display: flex;
						align-items: center;
						.icon{
							font-size: 36px;
							margin-right: 6px;
							color: #1989fa;
						}
						.locationtext{
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-size: 13px;
						}
						.map{
							margin-top: 14px;
							width: 200px;
							height: 100px;
						}
					}
					.jobBox{
						width: 260px;
						border-radius: 6px;
						overflow: hidden;
						background: #FFFFFF;
						color: #333333;
						box-sizing: border-box;
						box-shadow: 0 0 8px 0 rgba(0,0,0,.26);
						cursor: pointer;
						.titlebox{
							display: flex;
							justify-content: space-between;
							background: #eef4ff;
							padding: 6px;
							.name{
								width: 130px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								font-size: 13px;
							}
							.price{
								width: 130px;
								font-size: 13px;
								text-align: right;
							}
						}
						.textbox{
							padding: 6px;
							margin-top: 6px;
							.info{
								margin-bottom: 6px;
								font-size: 12px;
							}
						}
					}
					
				}
				.msgIcon{
					position: relative;
					top: 8px;
				}
			}
			.left{
				.avatarbox{
					float: left;
					margin-right: 9px;
				}
				.contentbox{
					float: left;
					background: #FFFFFF;
					color: #333333;
					&::after{
						content: '';
						width: 0;
						height: 0;
						border-top: 7px solid transparent;
						border-right: 7px solid #FFFFFF;
						border-bottom: 7px solid transparent;
						position: absolute;
						left: -6px;
						top: 12px;
					}
				}
				.msgIcon{
					float: left;
				}
				.msgTips{
					padding-left: 66px;
					margin-top: 4px;
					color: #999999;
					font-size: 12px;
				}
				.msgDate{
					color: #666666;
					font-size: 12px;
					padding-right: 76px;
					text-align: center;
				}
			}
			.right{
				.avatarbox{
					float: right;
					margin-left: 9px;
				}
				.msgDate{
					color: #666666;
					font-size: 12px;
					padding-left: 76px;
					text-align: center;
				}
				.contentbox{
					float: right;
					&::after{
						content: '';
						width: 0;
						height: 0;
						border-top: 7px solid transparent;
						border-left: 7px solid #1989fa;
						border-bottom: 7px solid transparent;
						position: absolute;
						right: -6px;
						top: 12px;
					}
					.locationBox{
						.icon{
							color: #FFFFFF;
						}
					}
				}
				.msgIcon{
					float: right;
				}
			}
			.clearFloat{
				zoom: 1;
				&::after{
					content: ""; 
					display: block; 
					height: 0; 
					clear: both; 
					visibility: hidden; 
				}
			}
		}
		.chatInput{
			border-top: 1px solid #ebebeb;
			height: 260px;
			background: #FFFFFF;
			position: relative;
			display: flex;
			flex-direction: column;
			.disabled{
				cursor: not-allowed;
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				background: rgba(177,177,177,.5);
				z-index: 2;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				color: #333333;
			}
			.meng{
				width: 100%;
				height: 20px;
				background: #f8f8f8;
				position: absolute;
				left: 0;
				top: -20px;
			}
			.menubox{
				width: 100%;
				height: 40px;
				border-bottom: 1px solid #ebebeb;
				display: flex;
				align-items: center;
				padding: 0 12px;
				.iconbox{
					font-size: 22px;
					margin-right: 22px;
					position: relative;
					height: auto;
					display: flex;
					align-items: center;
					cursor: default;
					color: #666666;
					&:hover{
						color: #1989fa;
					}
					.icon{
						margin-right: 2px;
					}
					span{
						font-size: 14px;
					}
					.uploadInput{
						width: 0;
						height: 0;
						position: absolute;
						left: 0;
						top: 0;
						opacity: 0;
					}
				}
				
			}
			.contenteditable{
				flex: 1;
				width: 100%;
				padding: 12px;
				box-sizing: border-box;
				overflow-y: auto;
				&:focus{
				  border:none;
				  outline:none;
				}
			}
			.btnbox{
				// width: 100%;
				padding: 0 12px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.tips{
					// margin-right: 12px;
					font-size: 12px;
					color: #999;
					text-align: right;
				}
			}
			
		}
	}
	
	.notdata{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 20px;
		.icon{
			width:108px;
			height:108px;
		}
		.tips{
			margin-top: 12px;
			text-align: center;
		}
	}
</style>