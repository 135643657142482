<template>
	<div>
		<div class="headbox" v-loading="systemLoading">
			<div class="welcome">
				<div class="logbox">
					<el-image class="img" :src="systemInfo?systemInfo.logo:require('assets/login/logo.png')" fit="cover" @click="test"></el-image>
				</div>
				<div>
					<span style="margin-right: .5em;">{{time_text}}</span>
					<span>
							<span style="margin-right: .5em;">欢迎使用</span> 
							<span v-if="guardName=='oa_partner_accounts'">{{systemInfo?systemInfo.name:'仁立通'}}合作商系统</span>
							<span v-else>{{systemInfo?systemInfo.shop_name:'仁立通数字管理系统'}}</span>
					</span>
				</div>
			</div>
			<div style="display: flex;align-items: center;">
				<div style="margin-right: 24px;" v-if="(guardName=='api' || $hasPermissions(2783))&&wallet">
					<el-button type="text" @click="openWallet()">企业钱包余额：{{(wallet.balance/100).toFixed(2)}}</el-button>
				</div>
				<div style="margin-right: 24px;" v-if="guardName=='orange_admin_user'">
					感谢一路有您！ <span v-if="days_since_onboarding">您已在职<span style="color: #1989fa;margin: 0 6px;">{{days_since_onboarding}}</span>天</span>
				</div>
				<!-- <div @click="toData" v-if="guardName=='api' || $hasPermissions(2198)" style="margin-right: 12px;color: #1989fa;cursor: pointer;"> 数据看板 </div> -->
				<div style="margin-right: 14px;display: flex;align-items: center;" v-if="guardName!='api'" @click="openEdit">
					<el-image style="width: 28px;height: 28px;border-radius: 50%;margin-right: 8px;" :src="userinfo.imgurl||require('assets/icon-avatar.png')" fit="cover" class='avatar'></el-image>
					<span>{{userinfo.name||userinfo.username}}</span>
				</div>
				<!-- <div v-if="guardName!='api'">
					<el-popover
					    placement="bottom"
					    width="184"
					    trigger="click">
						<div>
							<el-image style="width: 160px;height: 160px;display: block;" :src="qrcode" fit="contain"></el-image>
							<div style="text-align: center;margin-top: 8px;font-size: 14px;color: #666666;">我的推广码</div>
							<div style="text-align: center;margin-top: 8px;"><span style="cursor: pointer;font-size: 13px;color: #1989fa;" @click="copylink">点击复制推广链接</span></div>
						</div>
						<el-image slot="reference" style="width: 20px;height: 20px;display: block;margin-right: 12px;" :src="require('assets/icon-code.png')" fit="contain"></el-image>
					</el-popover>
				</div> -->
				<el-dropdown trigger="click" class="menu headernava" @command="handleCommand">
				  <div class="el-dropdown-link">
					  <el-image class="img" :src="require('assets/icon/icon-menu.png')" fit="contain"></el-image>
				  </div>
				  <el-dropdown-menu slot="dropdown">
				    <el-dropdown-item  command="changeuser" v-if="hasPermissions(2146)"><i class="el-icon-set-up el-icon--left"></i>修改账号</el-dropdown-item>
				    <el-dropdown-item command="changepwd" v-if="hasPermissions(1632)"><i class="el-icon-lock el-icon--left"></i>修改密码</el-dropdown-item>
				    <el-dropdown-item command="bindWechat"><i class="el-icon-document-checked el-icon--left"></i>绑定微信</el-dropdown-item>
				    <el-dropdown-item command="loginout"><i class="el-icon-switch-button el-icon--left"></i>退出登录</el-dropdown-item>
				  </el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		
		<el-dialog
			title="修改资料"
			:visible.sync="editShow"
			:close-on-click-modal="false"
			:lose-on-press-escape="false"
			:modal-append-to-body="false"
			v-loading="editLoading"
			width="500px">
			<div>
				<el-form ref="form" label-width="40px"> 
					<el-form-item label="头像">
						<div class="imgbox" @click="uploadShow=true">
							<el-image class="img" :src="editForm.imgurl||require('assets/icon-avatar.png')" fit="cover"></el-image>
						</div>
					</el-form-item>
					<el-form-item label="昵称">
						<el-input v-model="editForm.name" type="text" placeholder="请输入昵称"></el-input>
					</el-form-item>
					<el-form-item label="电话">
						<el-input v-model="editForm.phone" type="text" placeholder="请输入电话"></el-input>
					</el-form-item>
					<el-form-item label="账号">
						<el-input v-model="editForm.username" type="text" placeholder="请输入昵称" disabled></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer">
				<el-button @click="editShow = false">取 消</el-button>
				<el-button type="primary" @click="saveInfo">确 定</el-button>
			</span>
		</el-dialog>
		
		<el-dialog 
			title="绑定微信" 
			width="500px" 
			:visible.sync="bindShow" 
			:close-on-click-modal="false" 
			:lose-on-press-escape="false" 
			:modal-append-to-body="false" 
			@opened="getWechatCode()">
			<div v-loading="bindLoading">
				<div style="display: flex;justify-content: center;height: 320px;overflow: hidden;">
					<div id="wechatCodeBox"></div>
				</div>
			</div>
		</el-dialog>
		<xsk-manage :managehide.sync="uploadShow" is_rest @confirm="pickimg"></xsk-manage>
		
	</div>
</template>

<script>
	import {resetRouter} from '@/router/index.js'
	import {eltips,copylink,hasPermissions} from '@/util/util.js'
	import { mapState } from 'vuex';
	export default {
		name: 'headernav',
		data() {
			return {
				days_since_onboarding:JSON.parse(sessionStorage.getItem('userinfo')).days_since_onboarding,
				systemInfo:JSON.parse(sessionStorage.getItem('systemInfo')),
				hasPermissions:hasPermissions,
				time_text:'上午',
				guardName:sessionStorage.getItem('guardName'),
				userinfo:null,
				qrcode:'',
				editShow:false,
				editForm:{
					name:'',
					imgurl:'',
					phone:'',
					username:'',
				},
				uploadShow:false,
				editLoading:false,
				bindShow:false,
				bindLoading:false,
				systemLoading:false,
				wallet:null
			};
		},
		created() {
			this.userinfo = JSON.parse(sessionStorage.getItem('userinfo'))||null
			this.gettime()
			if(this.guardName!='api'){
				// this.getCodeImg()
			}
			this.getSystemInfo()
			this.getWalletInfo()
		},
		computed:{
			bindCode(){
				return this.$store.state.bindCode
			},
			...mapState({
				walletBalance: state => state.walletBalance,
			})
		},
		watch:{
			"$route":function(route){
				this.gettime()
			},
			bindCode:{
				immediate:true,
				handler(code){
					if(code){
						this.bindWechat(code)
					}
				}
			},
			walletBalance(val){
				console.log('钱包余额变动---',val)
				if(this.wallet){ this.wallet.balance = val }
			}
		},
		methods: {
			getWalletInfo(){
				this.axios.get('/api/oa/Wallet/get_wallet_info').then(res=>{
					if(res.code==200){
						this.wallet = res.data
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			openWallet(){
				this.$router.push({path:'/payconfig/wallet'})
			},
			getSystemInfo(){
				let data = {
					shop_id:sessionStorage.getItem('shopId')
				}
				this.systemLoading = true
				this.axios.get('/api/oa/SysTem/getSysTemInfo',{params:data}).then(res=>{
					this.systemLoading = false
					if(res.code==200){
						this.systemInfo = res.data
						sessionStorage.setItem('systemInfo',JSON.stringify(res.data))
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			bindWechat(code){
				this.bindLoading = true
				this.axios.post('/api/oa/WxOpen/bind_unionid',{code:code}).then(res=>{
					this.bindLoading = false
					if(res.code==200){
						this.$store.commit('changeBindCode', null)
						let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
						userinfo.unionID = res.data
						sessionStorage.setItem('userinfo',JSON.stringify(userinfo))
						eltips(res.msg,'success')
						this.bindShow = false
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			getWechatCode(){
				new WxLogin({
					self_redirect:false,
					id:"wechatCodeBox", 
					appid: "wxde370f4e7e986f83", 
					scope: "snsapi_login", 
					state:"bindWechatCode",
					redirect_uri: encodeURI(window.location.href),
					fast_login:0,
					href:'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDI0MHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyNDBweDt9Ci5pbXBvd2VyQm94IC5zdGF0dXMge3RleHQtYWxpZ246IGNlbnRlcjt9IAouaW1wb3dlckJveCAuc3RhdHVzIHA6bnRoLWNoaWxkKDIpe2Rpc3BsYXk6IG5vbmU7fSA='
				});
			},
			copylink(){
				let url = `${httpurl}/miniProgram/jump.html?cloudUserid=${this.userinfo.id}`
				copylink(url)
			},
			getuserinfo(){
				const guard_name = sessionStorage.getItem('guardName')
				this.axios.get('/api/me',{params:{guard_name:guard_name}}).then(res=>{
					if(res.code==200){
						this.userinfo = res.data
						sessionStorage.setItem('userinfo',JSON.stringify(res.data))
					}
				})
			},
			openEdit(){
				let item = JSON.parse(JSON.stringify(this.userinfo))
				this.editForm = {
					name:item.name,
					username:item.username,
					imgurl:item.imgurl,
					phone:item.phone
				}
				this.editShow = true
			},
			saveInfo(){
				this.editLoading = true
				let url = '/api/orange_manage/user/adminUser/'+this.userinfo.id
				let data = {
					name:this.editForm.name,
					imgurl:this.editForm.imgurl,
					phone:this.editForm.phone
				}
				const guard_name = sessionStorage.getItem('guardName')
				if(guard_name=='oa_partner_accounts'){
					url = '/api/oa/Partner/accountEdit'
					data.account_id = this.userinfo.id
				}
				this.axios.post(url,data).then(res=>{
					this.editLoading = false
					if(res.code==200){
						this.$tim.updateMyProfile({nick:data.name})
						if(data.imgurl) this.$tim.updateMyProfile({avatar: data.imgurl})
						this.getuserinfo()
						eltips(res.msg,'success')
						this.editShow = false
					}else{
						eltips(res.msg,'error')
					}
				})
			},
			pickimg(e){
				console.log(e)
				if(e.imgobjs.length>0){
					this.editForm.imgurl = e.imgobjs[0].qiniu_url
				}
			},
			getCodeImg(){
				let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
				let data = {
					path:'pages/login/logins',
					param:`promoter_id=${userinfo.id}`
				}
				this.axios.post('/api/admin/create_qrcode',data).then(res=>{
					this.qrcode = res.data.qrcode
				})
			},
			//根据时间改变提示语
			gettime(){
				let time = new Date().getHours()
				if(time<6){
					this.time_text = '夜深了,请注意休息'
				}else if(time<12){
					this.time_text = '早上好'
				}else if(time<14){
					this.time_text = '中午好'
				}else if(time<20){
					this.time_text = '下午好'
				}else if(time<22){
					this.time_text = '晚上好'
				}else{
					this.time_text = '夜深了,请注意休息'
				}
			},
			test(){
				this.$router.push({path: "/maxData"})
			},
			handleCommand(command) {
				let that = this;
				if(command == 'changeuser'){
					this.$router.push({path: "/account/editaccount"})
				}else if(command =='changepwd'){
					this.$router.push({path: "/account/editpassword"})
				}else if(command =='bindWechat'){
					let userinfo = JSON.parse(sessionStorage.getItem('userinfo'))
					if(userinfo.unionID){
						this.$confirm('此账号已经绑定微信，是否更换?', '微信换绑', {
						    confirmButtonText: '确定',
						    cancelButtonText: '取消',
						    type: 'warning'
						}).then(() => {
						    this.bindShow = true
						}).catch(() => {});
					}else{
						this.bindShow = true
					}
				} else {
					let guard_name = (JSON.parse(sessionStorage.getItem('userinfo'))).guard_name
					let data = {
						guard_name:guard_name
					}
					if(this.$store.state.sdk_ready){
						this.$tim.logout()
					}
					this.axios.post("/api/logout",data).then((res) => {
						if(res.code==200){
							let userinfo = JSON.parse(sessionStorage.getItem('userinfo')) 
							let type = userinfo.type
							let guard_name = userinfo.guard_name
							let guard_info = {
								type:type,
							}
							//判断登出到某个后台登录页面
							switch(guard_name){
								default:
									guard_info.guard_name = 'api'
									guard_info.name = userinfo.shop_name
									that.$router.replace({path: "/login",query:{shopId:sessionStorage.getItem('shopId')}})
									break;
								case 'orange_admin_user': //分后台
									this.$store.commit('ImStatus/null')
									
									if(this.$store.state.sdk_ready){
										this.$tim.logout()
									}
									guard_info.guard_name = 'orange_admin_user'
									that.$router.replace({path: "/adminlogin",query:{shopId:sessionStorage.getItem('shopId')}})
									break;
								case 'oa_partner_accounts': //分后台
									this.$store.commit('ImStatus/null')
									
									if(this.$store.state.sdk_ready){
										this.$tim.logout()
									}
									guard_info.guard_name = 'oa_partner_accounts'
									that.$router.replace({path: "/partnerlogin",query:{shopId:sessionStorage.getItem('shopId')}})
									break;	
							}
							
							resetRouter()
							this.$store.commit('changeRouteadds', 0)
							sessionStorage.setItem('guard_info',JSON.stringify(guard_info))
							sessionStorage.removeItem('routestext')
							sessionStorage.removeItem('routes')
							sessionStorage.removeItem('userinfo')
							sessionStorage.removeItem('token')
							sessionStorage.removeItem('IMtoken')
						}else {
							this.$message(res.msg)
						}
					})
				}
			},
			toData(){
				this.$router.push({path: "/dataView"})
			}

		}
	}
</script>

<style>
	.el-dropdown-menu{
		padding: 0 !important;
	}
	
	.el-dropdown-menu__item{
		line-height: 66px !important;
		padding: 0 30px !important;
	}
</style>

<style lang="less" scoped>
	
	.headbox{
		display: flex;
		justify-content: space-between;
		height: 50px;	
		background: #ffffff;
		padding: 0 20px 0 20px;
		box-shadow: 0px 4px 4px -4px rgba(0,0,0,.2);
		.welcome{
			display: flex;
			align-items: center;
			line-height: 50px;
			font-size: 18px;
			font-weight: bold;
			span{
				color: #1989fa;
			}
			.logbox{
				margin-right: 12px;
				width: 40px;
				height: 40px;
				.img{
					width: 100%;
					height: 100%;
				}
			}
		}
	
		.menu{
			display: inline-block;
			float: right;
			height: 50px;
			padding-top: 16px;
			cursor: pointer;
			.img{
				width: 20px;
				height: 17px;
			}
		}
	}

	.imgbox{
		width: 120px;
		height: 120px;
		border: 1px dashed #e3e3e3;
		padding: 8px;
		.img{
			width: 100%;
			height: 100%;
		}
	}
</style>
