<template>
	<div id="xskVideoManage">
		<el-dialog title="添加视频" center :visible.sync="managehide" :append-to-body="appendToBody" :modal-append-to-body="false" width="990px" :close-on-click-modal="false" :show-close="false">
			<div id="xskVideoManageBody">
				<div v-loading="loading">
					<div class="headbox">
						<div class="btnbox">
							<el-button class="addbtn" size="small" type="primary">+添加视频</el-button>
							<input id="file" class="addinput" accept="video/*" type="file" :multiple="false" @change="uploadVideo"/>
						</div>
					</div>
					
					<div class="videolist">
						<div class="nodata" v-if="list.length == 0">
							<i class="el-icon-document-copy" style="font-size: 80px;color: #939799;"></i>
							<div class="tips">
								暂无数据，
								<span>去添加</span>
								<input id="videofile" class="input" accept="video/*" type="file" :multiple="false" @change="uploadVideo"/>
							</div>
						</div>
						<div class="videobox" v-for="(item,index) in list" :key="index" @click="chooseVideo(item)">
							<video class="video" :src="item.qiniu_url"/>
							<div class="name">{{item.original_name}}</div>
							<div class="time">{{item.created_at}}</div>
							<div class="choose" v-if="active.id==item.id"><i class="el-icon-success icon"></i></div>
							<i v-if="active.id!=item.id" @click.stop="delVideo(item.id)" class="delicon el-icon-error"></i>
						</div>
					</div>
					
					<div class="pages" v-if="total>0">
						<el-pagination
							background
							@current-change="chagepage"
							:current-page="page"
							:small="true"
							:page-size="10"
							layout="total, prev, pager, next,jumper"
							:total="total"
						></el-pagination>
					</div>
				</div>
			
				<span slot="footer">
					<el-button size="medium" type="primary" plain @click="hidemanage">取 消</el-button>
					<el-button size="medium" type="primary" @click="confirmVideo">确 定</el-button>
				</span>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import * as qiniu from 'qiniu-js';	
import { eltips } from '@/util/util.js';
export default {
	name: 'xskVideoManage',
	data() {
		return {
			userinfo:JSON.parse(sessionStorage.getItem('userinfo')),
			loading:false,
			list:[],
			page:1,
			total:0,
			active:'',
			QnToken:'',//七牛云token
			subscription:'',//七牛云上传对象
		};
	},
	created() {
		this.getQnToken()
		this.getVideoList()
	},
	props: {
		//控制插件显示 
		managehide: {
			type: Boolean,
			default: false
		},
		appendToBody: {
			type: Boolean,
			default: false
		},
		//是否多选
		multiple:{
			type: Boolean,
			default: false
		},
		//上传数量限制
		count:{
			type:Number,
			default:9
		},
		//打开前是否重置选中
		is_rest:{
			type: Boolean,
			default: false
		}
	},
	watch: {
		//插件显示监听 显示时禁止页面滚动
		managehide(val) {
			let preD = function(e) {
				e.preventDefault();
			};
			if (val) {
				if(this.is_rest){
					this.active = ''
				}
				document.body.style.overflow = 'hidden';
				document.addEventListener('touchmove', preD, { passive: false });
			} else {
				document.body.style.overflow = ''; // 出现滚动条
				document.removeEventListener('touchmove', preD, { passive: false });
			}
		}
	},
	methods:{
		chooseVideo(item){
			if(this.active&&this.active.id==item.id){
				this.active = ''
			}else{
				this.active = item
			}
		},
		confirmVideo(){
			this.$emit('update:managehide',false)
			this.$emit('confirm',this.active)
		},
		hidemanage(){
			this.$emit('update:managehide',false)
		},
		uploadVideo(e){
			let that = this;
			let file = e.target.files[0];
			document.getElementById('file').value = null;
			if(this.list.length==0) document.getElementById('videofile').value = null;
			if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
				eltips('请上传支持的视频格式（mp4，flv，avi，wmv，rmvb，mov）', 'error');
				return false;
			}
			
			if(this.size!=0&&file.size/1024/1024>this.size){
				eltips('视频大小不得超过'+this.size+'M', 'error');
				return false;
			}
			
			//设置视频key 时间戳+文件名
			let key = this.userinfo.shop_id + '_' + this.userinfo.guard_name + '_' + this.userinfo.id + '_' + new Date().getTime() + '_' + file.name;
			
			//设置七牛云参数 config
			let config = {
				concurrentRequestLimit: 2
			};
			
			//设置七牛云参数 putExtra
			let putExtra = {
				//原文件名
				fname: '',
				//用来放置自定义变量
				params: {},
				//限制上传文件类型
				mimeType: null
			};
			
			//设置七牛云上传回调事件 observer
			let observer = {
				//上传过程的监听函数 result参数带有total字段的 object，包含loaded、total、percent三个属性)
				next(result) {
					// let percent = parseInt(result.total.percent)
				},
				//上传失败回调
				error(err) {
					that.loading = false
				},
				// 上传完成回调
				complete(res) {
					let videoSrc = 'https://qiniu.job695.com/'+res.key
					let data = {
						original_name:file.name,
						size:file.size,
						ext:file.type,
						qiniu_url:videoSrc,
						name:key,
						type:2,
					}
					that.sendUrl(data)
				}
			};
			
			//创建七牛云上传
			let observable = qiniu.upload(file, key, this.QnToken, putExtra, config);

			this.loading = true
			//触发上传并执行上传回调
			this.subscription = observable.subscribe(observer)
		},
		sendUrl(data){
			this.axios.post('/api/upload/image_manage',data).then(res=>{
				this.loading = false
				if(res.code==200){
					eltips(res.msg,'success')
					this.page = 1
					this.getVideoList()
				}else{
					eltips(res.msg,'error')
				}
			})
		},
		chagepage(e){
			this.page = e
			this.getVideoList()
		},
		getVideoList(){
			let data = {
				page:this.page,
				type:2
			}
			this.loading = true
			this.axios.get('/api/upload/manage_img_list',{params:data}).then(res=>{
				this.loading = false
				if(res.code==200){
					this.total = res.data.total
					this.list = res.data.data
				}else{
					eltips(res.msg,'error')
				}
			})
		},
		//获取七牛云token
		getQnToken(){
			this.axios.post('/api/upload/get_qiniu_token',{type:'video'}).then(res => {
				this.QnToken = res.data;
			});
		},
		//删除视频
		delVideo(id){
			this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			  type: 'warning'
			}).then(() => {
				this.loading = true
				this.axios.delete('/api/upload/manage_img_del/'+id).then(res=>{
					this.loading = false
					if(res.code==200){
						eltips(res.msg,'success')
						this.getVideoList()
					}else{
						eltips(res.msg,'error')
					}
				})
			}).catch(() => {});
		},
	}
};
</script>

<style lang="less">
/* element 样式修改 */
#xskVideoManage .el-dialog__footer,
#xskVideoManage .el-dialog__body,
#xskVideoManage .el-dialog__header {
	padding: 14px 16px !important;
}
#xskVideoManage .el-dialog__headerbtn {
	top: 15px;
}
#xskVideoManage .el-dialog__header {
	font-weight: bold;
	border-bottom: 1px solid #e9edef;
}
#xskVideoManage .el-dialog__body {
	padding: 20px !important;
	padding-bottom: 0 !important;
	border-bottom: 1px solid #e9edef;
}
#xskVideoManage .el-pagination--small .el-pagination__editor.el-input .el-input__inner {
	height: 22px !important;
	line-height: 22px !important;
}

#xskVideoManageBody{
	position: relative;z-index: 99;
	.headbox{
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 1px solid #e9edef;
		.btnbox{
			position: relative;
			.addbtn{
				width: 90px;
			}
			.addinput{
				width: 90px;
				height: 32px;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				cursor: pointer;
			}
		}
	}

	.videolist{
		padding-top: 20px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		min-height: 200px;
		.nodata {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			.tips {
				margin-top: 10px;
				font-size: 14px;
				position: relative;
				span {
					color: #1989fa;
				}
				.input {
					width: 45px;
					height: 18px;
					position: absolute;
					right: 0;
					top: 0;
					opacity: 0;
				}
			}
		}
		
		.videobox{
			width: 178px;
			margin-right: 15px;
			margin-bottom: 15px;
			position: relative;
			&:nth-child(5n){
				margin-right: 0;
			}
			.video{
				width: 178px;
				height: 98px;
				background: #000000;
			}
			.name{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				font-size: 12px;
			}
			.time{
				font-size: 12px;
				color: #999;
			}
			.choose{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 178px;
				height: 98px;
				background: rgba(0,0,0,.6);
				position: absolute;
				left: 0;
				top: 0;
				.icon{
					font-size: 24px;
					color: #007aff;
				}
			}
			.delicon{
				font-size: 20px;
				position: absolute;
				right: -10px;
				top: -10px;
				color: #999;
				display: none;
			}
			&:hover{
				.delicon{
					display: block;
				}
			}
		}
		
	}

	.pages{
		border-top: 1px solid #e9edef;
		padding: 16px 0;
		display: flex;
		justify-content: center;
	}
}
</style>
