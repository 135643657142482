<!--
areahide  是否隐藏区  true隐藏  false不隐藏  默认false
@confirm 父组件获取回传信息 

插件依赖 element-ui
线上客Saas组件库 省市区选择器 --- 2020.09.28  
 -->

<template>
	<div id="xskAddpick">
		<el-cascader :size="size" filterable style="width: 100%;" ref="myCascader" :disabled="disabled" v-model="city" :show-all-levels="is_levels" clearable placeholder-style="color:#000"  :placeholder="placeholder||'请选择'" clearable :props="addprops" :options="options" @change="changeaddress"></el-cascader>
	</div>
</template>

<script>
import { eltips } from '@/util/util.js';
let that;
export default {
	name: 'xskAddpick',
	data() {
		let _self = this;
		return {
			city: [],
			options:[],
			addprops:{
				multiple:this.multiple,
				checkStrictly :this.checkStrictly,
				emitPath:this.emitPath,
				value:this.propsValue,
			}
		};
	},
	props: {
		propsValue:{
			type:String,
			default:'value'
		},
		size:{
			type:String,
			default:'medium'
		},
		// 是否隐藏 区
		areahide: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			default: false
		},
		placeholder:{
			type:String,
			default:'',
		},
		multiple:{
			type:Boolean,
			default:false,
		},
		checkStrictly:{
			type:Boolean,
			default:false,
		},
		emitPath:{
			type:Boolean,
			default:true,
		},
		is_levels: {
			type: Boolean,
			default: true
		},
		default: {
			type: Array,
			default: ()=>[]
		},
	},
	created() {
		that = this;
		if(this.default.length>0) this.city = this.default;
		this.initaddress()
	},
	watch:{
		default:(val)=>{
			if(val.length>0){
				that.city = val
			}else{
				that.city = ''
			}
		}
	},
	methods: {
		//获取省份列表
		initaddress(){
			let that = this;
			this.axios.get('/api/get_area_tree').then(res=>{
				this.options = res.data
				this.options.forEach((e,i)=>{
					e.label = e.name
					e.value = e.id,
					e.children = e.city
					for(let i=0;i<e.children.length;i++){
						let e1 = e.children[i]
						e1.label = e1.name
						e1.value = e1.id
						if(!that.areahide){
							e1.children = e1.city
							e1.children.forEach((e2)=>{
								e2.label = e2.name
								e2.value = e2.id
							})
						}
					}
				})
				if(this.values){
					this.city = this.values
				}
			})
		},
		//获取插件返回信息
		changeaddress(e){
			let that = this
			this.$nextTick(()=>{
				let data
				if(e.length==0){
					data = false
				}else{
					if(!this.multiple){
						let labels = this.$refs['myCascader'].getCheckedNodes()[0].pathNodes
						data = {
							obj:{
								province:{
									label:labels[0].data.name,
									value:labels[0].data.id,
									code:labels[0].data.area_code,
								}
							},
							labes:labels[0].data.name,
							labesvalue:e
						}
						
						if(labels.length>1){
							data.obj.city = {
								label:labels[1].data.name,
								value:labels[1].data.id,
								code:labels[1].data.area_code,
							}
							data.labes = labels[0].data.name+labels[1].data.name
						}
						
						if(!this.areahide&&labels.length==3){
							data.obj.area = {
								label:labels[2].data.name,
								value:labels[2].data.id,
								code:labels[2].data.area_code,
							}
							data.labes = labels[0].data.name+labels[1].data.name+labels[2].data.name
						}
					}else{
						data = this.city
					}
				}
				this.$emit('confirm',data)
			})

		},
	}
};
</script>

<style>
	#xskAddpick .el-icon-circle-close{
		top: 0px;
	}
</style>
<style scoped lang="less">
#xskAddpick {
	display: inline-block;
}


</style>
