export function handlePaste(event, el, vm) {
	
	const clipboardData = event.clipboardData || window.clipboardData;
	const types = Array.from(clipboardData.types);
	if (types.includes('text/html')) {
		event.preventDefault();
		// 处理粘贴的 HTML
		const pastedData = clipboardData.getData('text/html');
		const sanitizedText = sanitizeHTML(pastedData,el,vm);
		// el.innerText  = sanitizedText
		// el.insertAdjacentHTML('beforeend', sanitizedText);
		insertHTMLContent(el,sanitizedText)
	}
	if (types.includes('Files')) {
		event.preventDefault();
		const files = Array.from(clipboardData.files);
		console.log(files)
		const imageFiles = files.filter(file => file.type.includes('image/'));
		if (imageFiles.length > 0) {
			imageFiles.forEach(item => {
				handleImage(item, el, vm);
			})
		}
	}
}

function insertHTMLContent(el, content) {
	const selection = window.getSelection();
	const range = selection.getRangeAt(0);
	range.deleteContents();
    // 按行分割文本内容
    const lines = content.split('\n');
    // 遍历每行文本内容，并将其作为文本节点插入到目标元素中
    lines.forEach((line, index) => {
        const textNode = document.createTextNode(line);
        el.appendChild(textNode);
        // 如果不是最后一行，则插入一个换行符
        if (index < lines.length - 1) {
            el.appendChild(document.createElement('br'));
        }
    });
	setSelectionCollapse(el)
}

function setSelectionCollapse(el){
	const range = document.createRange();
	range.selectNodeContents(el);
	range.collapse(false); // 将光标移动到范围的末尾
	const selection = window.getSelection();
	selection.removeAllRanges(); // 清除之前的选择范围
	selection.addRange(range)
}

function sanitizeHTML(html,el,vm) {
    const temp = document.createElement('div');
        temp.innerHTML = html;
    
        // 提取文字内容并保留块元素的换行
        const extractText = (node) => {
            let text = '';
            node.childNodes.forEach(child => {
                if (child.nodeType === Node.TEXT_NODE) {
                    text += child.textContent.trim() + ' ';
                } else if (child.nodeType === Node.ELEMENT_NODE) {
                    const tagName = child.tagName.toLowerCase();
                    if (['div', 'table', 'tr', 'p'].includes(tagName)) {
                        text += '\n' + extractText(child);
                    } else if (['td', 'th', 'li'].includes(tagName)) {
                        text += extractText(child) + ' ';
                    } else {
                        text += extractText(child);
                    }
                }
            });
            return text.trim();
        };
    
        const processedText = extractText(temp);
        return processedText;
}


export function handleImage(file, el, vm) {
	const reader = new FileReader();
	reader.onload = (event) => {
		const image = new Image();
		image.src = event.target.result;
		image.onload = () => {
			insertImage(image.src, el, vm);
		};
	};
	reader.readAsDataURL(file);
}

function insertImage(src, el, vm) {
	const image = document.createElement('img');
	image.src = src;
	image.style.maxWidth = '100px'; // 图片宽度不超过160
	image.style.maxWidth = '140px'; // 图片宽度不超过160
	image.style.display = 'block';
	image.style.objectFit = 'cover'

	image.addEventListener('click', () => {
		vm.$emit('imgPreview', src)
	})
	el.appendChild(image);
	setSelectionCollapse(el)
}
