<template>
	<div class="menu">
		 <el-menu 
			unique-opened
			:default-active="'menu'+firstId"
			>
			<submenu v-for="item in menuList" :key="item.id" :item="item" :msgCountList="msgCountList"></submenu>
		</el-menu>
	</div>
</template>

<script>
	import submenu from './submenu.vue'
	import {wssUrl} from '@/util/util.js'
	export default {
		name: 'asidenav',
		components:{submenu},
		data() {
			return {
				menuList: [],
				msgCountList:[],
				firstId:'',
				websocket:null,
				websocketTimer:null
			};
		},
		async created() {
			let routes = JSON.parse(sessionStorage.getItem('routestext'))
			this.firstId = this.$route.meta.id
			this.menuList = this.recastRoute(routes,this.$route.path)
			this.initWebSocket()
		},
		mounted() {
			window.addEventListener("online",()=>{
				console.log('网络已连接')
				if(!this.websocket||this.websocket.readyState!=1){
					clearTimeout(this.websocketTimer)
					this.initWebSocket()
				}
			})
			window.addEventListener("offline",()=>{
				console.log('网络断开连接')
			})
			//判断页面长时间被切换至后台 
			window.addEventListener("visibilitychange",()=>{
				if(document.hidden) return;
				console.log('页面切换前台')
				if(!this.websocket||this.websocket.readyState!=1){
					clearTimeout(this.websocketTimer)
					this.initWebSocket()
				}
			})
		},
		watch:{
			'$route':function(route){
				this.firstId = route.meta.id
			},
		},
		beforeDestroy() {
			if(this.websocket) this.websocket.close()
			clearTimeout(this.websocketTimer);
		},
		methods: {
			initWebSocket() { //初始化weosocket
				console.log('初始化weosocket')
				const wsuri = wssUrl;
				this.websocket = new WebSocket(wsuri);
				this.websocket.onmessage = this.websocketonmessage;
				this.websocket.onopen = this.websocketonopen;
				this.websocket.onerror = this.websocketonerror;
				this.websocket.onclose = this.websocketclose;
			},
			async websocketonopen() { //连接成功
				console.log('weosocket连接成功')
				clearTimeout(this.websocketTimer)
				this.msgCountList = await this.getMenuCount() //链接成功后查询菜单消息提示数
				const data = {
					authorization : 'Bearer'+sessionStorage.getItem("token"),
					guardname : sessionStorage.getItem("guardName"),
					shopid : sessionStorage.getItem("shopId")
				}
				this.websocket.send(JSON.stringify(data))
			},
			websocketonerror() { //连接错误尝试重连
				console.log('websocket连接错误')
				if(!sessionStorage.getItem("token")) return
				clearTimeout(this.websocketTimer)
				this.websocketTimer = setTimeout(()=>{
					this.initWebSocket();
				},2000)
			},
			websocketclose() { //连接关闭尝试重连
				console.log('websocket连接关闭')
				if(!sessionStorage.getItem("token")) return
				clearTimeout(this.websocketTimer)
				this.websocketTimer = setTimeout(()=>{
					this.initWebSocket();
				},2000)
			},
			websocketonmessage(e) { //数据接收
				let res = JSON.parse(e.data)
				if(res.type=='MENU') this.updateMenuCount(res.menu_data)
				if(res.type=='RECHARGE') this.alipayRechargeOrder(res)
				if(res.type=='WALLET_CANGES') this.walletChange(res)
			},
			//钱包变动
			walletChange(res){
				this.$store.commit('setWalletbalance',res.balance)
			},
			//支付宝充值订单号
			alipayRechargeOrder(res){
				if(res.status=='SUCCESS'){
					this.$store.commit('setAlipayOrderNumber',res.order_number)
				}
			},
			//更新当前消息提示
			updateMenuCount(meunArray){
				meunArray.forEach(item=>{
					const obj = this.msgCountList.find(e=>e.nav_id==item.nav_id)
					if(obj){
						const index = this.msgCountList.findIndex(e=>e.nav_id==item.nav_id)
						obj.count+=item.count
						this.$set(this.msgCountList,index,obj)
					}else{
						this.msgCountList.push(item)
					}
				})
			},
			//获取当前菜单消息提示数
			getMenuCount(){
				return new Promise((resolve) => {
					this.axios.get('/api/oa/Statistics/Nav/nav_statisticsxx').then(res=>{
						if(res.code==200){
							resolve(res.data)
						}else{
							eltips(res.msg,'error')
							resolve([])
						}
					})
				})
			},
			recastRoute(arr,path){
				let newArr = []
				arr.forEach(item => {
					if (item.children&&item.children.length>0){
						item.children = this.recastRoute(item.children)
					}
					if (item.meta.layout == 1) {
						newArr.push(item)
					}
					if(path&&item.path==path){
						this.firstId = item.id
					}
				})
				return newArr
			},
		}
	}
</script>

<style>
	.el-menu-item .is-active{
		background: #FFFFFF;
	}
</style>
<style lang="less" scoped>
	.menu {
		height: 100%;
		background: #FFF;
		position: relative;
		overflow-y: auto;
		overflow-x: hidden;
	}
</style>
