<template>
	<div id="recom">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'recom',
	data() {
		return {
			keep:0,
		};
	},
	methods:{
		
	}
};
</script>



<style scoped lang="less">
#recom{
	// position: relative;
	// height: calc(100vh - 70px);
	// overflow-y: auto;
}
</style>
